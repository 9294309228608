import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { WhyUsPage } from '../components/WhyUsPage';

interface Props {}

// @ts-ignore
const WhyUsCity: React.FC = ({ location }) => {
  const city = location && location.state ? location.state.city : null;
  return (
    <DefaultLayout>
      <div className='container mx-auto px-6 lg:px-20 '>
        <WhyUsPage content={city} />
      </div>
    </DefaultLayout>
  );
};

export default WhyUsCity;
